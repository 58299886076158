import querystring from 'querystring';

import { getConfig } from '@/shared/getEnv';
import assets from '@/__generated__/assets.json';

type ImageKey = keyof typeof assets.images;
type Option = {
  dynamic?: boolean;
  baseUrl?: string;
};

const { publicRuntimeConfig: config } = getConfig();
const cdnUrl = config.assetPrefix ? `${config.assetPrefix}/public` : '';

function makeCdnUrl(image: string, options: Option = {}) {
  const { dynamic, baseUrl, ...params } = options;

  if (dynamic || !cdnUrl) {
    return image;
  }

  if (baseUrl) {
    return `${baseUrl}${image}`;
  }

  const query = querystring.encode({ ...params });
  return `${cdnUrl}${image}?${query}`;
}

function getAsset(image?: string | Partial<string>, options?: Option): string {
  if (typeof image === 'string') {
    return cdnUrl ? makeCdnUrl(image, options) : image;
  }

  if (image === undefined) {
    throw Error(`Asset is not found`);
  }

  return getAsset(image, options);
}

const images = (key: ImageKey, options?: Option) => {
  const image = assets.images[key];

  return getAsset(image, options);
};

export type { ImageKey };
export { images, getAsset };
