import nextGetConfig from 'next/config';

type Config = {
  publicRuntimeConfig: {
    apiEndpoint: string;
    basePath: string;
    assetPrefix: string;
    origin: string;
    googleMeasurementId: string;
    noIndexOrigin: string;
    language: string;
    country: string;
  };
};

function getConfig() {
  return nextGetConfig() as Config;
}

export { getConfig };
