const colors = {
  gray: {
    25: '#FAFAFA',
    850: '#151A23',
  },
  teal: {
    100: '#EAF8F9',
    500: '#2EB5C0',
  },
  red: {
    450: '#F26157',
  },
  green: {
    90: '#3B9D3F',
    100: '#7ea16e',
    250: '#7FB069',
  },
  "light-seagreen": {
    110: '#003D39'
  }
};

export { colors };
