import { extendTheme } from '@chakra-ui/react';
import { ColorModeOptions } from '@chakra-ui/color-mode';

import { colors } from './colors';

const CONFIG: ColorModeOptions = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

const theme = extendTheme({
  colors,
  config: CONFIG,
  styles: {
    global: (_props) => ({
      'html, body, #__next': {
        height: '100%',
      },
    }),
  },
  fonts: {
    heading:
      'Open Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif',
    body: 'Titillium Web, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif',
  },
});

const getTheme = () => {
  return theme;
};

export { getTheme };
