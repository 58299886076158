import Head from 'next/head';

import { getAsset } from '@/shared/assets';

export default function Favicon() {
  return (
    <Head>
      <link
        rel="apple-touch-icon"
        href={getAsset(`/assets/favicons/apple-touch-icon.png`)}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={getAsset(`/assets/favicons/apple-touch-icon.png`)}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={getAsset(`/assets/favicons/favicon-32x32.png`)}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={getAsset(`/assets/favicons/favicon-16x16.png`)}
      />
      <link rel="manifest" href={`/assets/favicons/site.webmanifest`} />
      <link
        rel="mask-icon"
        href={getAsset(`/assets/favicons/safari-pinned-tab.svg`)}
        color="#5bbad5"
      />
      <link
        rel="shortcut icon"
        href={getAsset(`/assets/favicons/favicon.ico`)}
      />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta
        name="msapplication-config"
        content={`/assets/favicons/browserconfig.xml`}
      />
      <meta name="theme-color" content="#ffffff" />
    </Head>
  );
}
