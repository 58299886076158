import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { ChakraProvider } from '@chakra-ui/react';

import { getTheme } from '@/uikit/theme';
import Favicon from '@/uikit/component/Favicon';
import { getConfig } from '@/shared/getEnv';

const theme = getTheme();
const { publicRuntimeConfig: config } = getConfig();

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ChakraProvider resetCSS theme={theme}>
      <Head>
        <title>Ruangguru Engineering Academy</title>
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1, initial-scale=1"
        />
      </Head>
      <Favicon />
      {config.googleMeasurementId && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${config.googleMeasurementId}`}
            strategy="afterInteractive"
          />
          <Script id="google-analytics" strategy="afterInteractive">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
            
              gtag('config', '${config.googleMeasurementId}');
            `}
          </Script>
        </>
      )}
      <Component {...pageProps} />
    </ChakraProvider>
  );
}
export default MyApp;
